
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    /*
| Developed by Starton
| Filename : _app.tsx
*/
import '../styles/globals.css';
import '../extends/yup/methods.yup';
import React from 'react';
import { EmotionCache } from '@emotion/react';
import type { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { NextComponentType, NextPageContext } from 'next/dist/shared/lib/utils';
import { SWRConfig } from 'swr';
import { Box } from '@mui/material';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import dynamic from 'next/dynamic';
import { AppCacheProvider } from '@mui/material-nextjs/v13-pagesRouter';
import { useRouter } from 'next/router';
import { GoogleTagManager } from '@/services/gtag/GoogleTagManager';
import { store } from '@/stores/store';
import { AvailableLanguages } from '@/contracts';
import { DashboardLayout } from '@/components/layouts';
import { StartonExtendNextPage } from '@/contracts/next-layout';
import { CookiesService } from '@/services/cookies/cookies.service';
import { WhiteLabelProvider } from '@/components/white-labelling/context/whitelabel.provider';
import { ThemeProvider } from '@/components/layouts/app/theme/ThemeProvider';
import { DefaultWhiteLabelSeo } from '@/components/layouts/app';
const CrispWithNoSSR = dynamic(() => import('@/components/common/crisp').then((m) => m.CrispChat), { ssr: false });
/*
|--------------------------------------------------------------------------
| SWR Global Default Config
|--------------------------------------------------------------------------
*/
const SWRGlobalDefaultConfig = {
    refreshInterval: 60000, // 1 minute
};
/*
|--------------------------------------------------------------------------
| Application interface modifier
|--------------------------------------------------------------------------
*/
interface AppEmotionProps extends Omit<AppProps, 'Component'> {
    Component: NextComponentType<NextPageContext, any, any> & StartonExtendNextPage;
    emotionCache?: EmotionCache;
}
/*
|--------------------------------------------------------------------------
| PostHog
|--------------------------------------------------------------------------
*/
if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'development') {
    posthog.init(String(process.env.NEXT_PUBLIC_POSTHOG_KEY), {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
        // Enable debug mode in development
        // loaded: (posthog) => {
        // This should be considered as harassment, it will freaking flood your console
        // if (process.env.NODE_ENV === 'development') posthog()
        // },
        capture_pageview: false,
        autocapture: false,
    });
}
/*
|--------------------------------------------------------------------------
| Application layout
|--------------------------------------------------------------------------
*/
function StartonApp({ Component, pageProps, router, emotionCache }: AppEmotionProps) {
    const key = React.useId();
    // API language change
    // ----------------------------------------------------------------------------
    React.useEffect(() => {
        const currentLanguage = (router.locale?.toLowerCase() as unknown as AvailableLanguages) ?? 'en';
        // Change NEXT_LOCALE
        const nextLocaleCookie = CookiesService.getCookie('NEXT_LOCALE');
        if (router.locale !== router.defaultLocale || router.locale !== nextLocaleCookie) {
            const date = new Date();
            const expireMs = 100 * 24 * 60 * 60 * 1000; // 100 days
            date.setTime(date.getTime() + expireMs);
            CookiesService.setCookie('NEXT_LOCALE', currentLanguage, {
                expires: date,
            });
        }
        // Save local used into Sentry
        // Sentry.setTag('locale', router.locale)
    }, [router.locale, router.defaultLocale]);
    // PostHog page view
    //--------------------------------------------------------------------------
    React.useEffect(() => {
        // Track page views
        const handleRouteChange = () => posthog?.capture('$pageview');
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router]);
    // Render
    // ----------------------------------------------------------------------------
    return (<AppCacheProvider emotionCache={emotionCache}>
			<Provider store={store}>
				<WhiteLabelProvider defaultConfiguration={{
            isStarton: true,
            colorMode: 'dark',
            name: 'Starton',
            slug: 'starton',
            logo: 'default',
            logoType: 'default',
            twitterPageUrl: 'https://twitter.com/starton_com',
            featuredFlags: {
                poweredBy: false,
                enableRelayer: true,
                enableListener: true,
                enablePlayground: true,
                enableRPCNode: true,
            },
            homeVideos: {
                transactionManager: undefined,
                smartContract: undefined,
                ipfs: undefined,
                monitor: undefined,
            },
        }}>
					<ThemeProvider>
						<DefaultWhiteLabelSeo router={router}/>
						<GoogleTagManager>
							<PostHogProvider client={posthog}>
								<SWRConfig value={Component?.swrConfig ?? SWRGlobalDefaultConfig}>
									<AnimatePresence mode="wait">
										<motion.div key={key} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.5 } }}>
											<DashboardLayout layout={Component?.layout ?? 'default'}>
												<Component {...pageProps}/>
											</DashboardLayout>
										</motion.div>
										<Splashscreen key="splashscreen"/>
									</AnimatePresence>
								</SWRConfig>
							</PostHogProvider>
						</GoogleTagManager>
					</ThemeProvider>
				</WhiteLabelProvider>
			</Provider>
			<CrispWithNoSSR />
		</AppCacheProvider>);
}
// Splashscreen component
const Splashscreen = () => {
    const [isVisible, setIsVisible] = React.useState(true);
    const { isReady } = useRouter();
    const controls = useAnimation();
    React.useEffect(() => {
        if (isReady) {
            const timeout = setTimeout(async () => {
                await controls.start({ opacity: 0 });
                setIsVisible(false);
            }, 1000);
            return () => clearTimeout(timeout);
        }
    }, [isReady, controls]);
    if (!isVisible)
        return null;
    return (<motion.div initial={{ opacity: 1 }} animate={controls} transition={{ duration: 0.5 }} style={{
            position: 'fixed',
            top: 0,
            height: '100vh',
            width: '100vw',
            zIndex: 9999,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
			<Box height="100%" width="100%" sx={{
            backgroundColor: (theme) => theme.palette.background.default,
        }}/>
		</motion.div>);
};

    export default __appWithI18n(StartonApp, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  